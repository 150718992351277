import "./Header.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Breakpoint } from "react-socks";
import { myBagToggle } from "../../redux/slices/myBagToggleSlice";
import useGetUser from "../../hooks/useGetUser";
import INPUT from "../Master/INPUT";
import {
  BAG_IC,
  CLOSE_BLACK_IC,
  CLOSE_IC,
  LIKE__IC,
  MENU_IC,
  PERSON_IC,
  SEARCH_IC,
} from "../../assets";

function Header({
  isRetailerPage,
  sidebarToggle,
  setSidebarToggle,
  headerLabelsData,
}) {
  const catData = [
    {
      title: "Optical Frames",
      id: "668540397db56839146d6f82",
      subCategory: [
        {
          img: "/glass_1.png",
          title: "Everyday Optical Frames",
          id: "668540397db56839146d6f82",
        },
        {
          img: "/glass_2.png",
          title: "Regular Optical Frames",
          id: "668540397db56839146d6f82",
        },
        {
          img: "/glass_3.png",
          title: "Black Optical Frames",
          id: "668540397db56839146d6f82",
        },
        {
          img: "/glass_4.png",
          title: "Everyday Optical Frames",
          id: "668540397db56839146d6f82",
        },
        {
          img: "/glass_1.png",
          title: "Regular Optical Frames",
          id: "668540397db56839146d6f82",
        },
      ],
    },
    {
      title: "Reading Glasses",
      id: "668540397db56839146dd539",
      subCategory: [
        {
          img: "/glass_2.png",
          title: "Everyday Reading Frames",
          id: "668540397db56839146dd539",
        },
        {
          img: "/glass_3.png",
          title: "Regular Reading Frames",
          id: "668540397db56839146dd539",
        },
      ],
    },
    {
      title: "Sunglasses",
      id: "668540567db56839146d6f8e",
      subCategory: [
        {
          img: "/sunglass_1.png",
          // img: "/glass_3.png",
          title: "Everyday Sunglasses Frame",
          id: "668540567db56839146d6f8e",
        },
        {
          img: "/sunglass_1.png",
          // img: "/glass_3.png",
          title: "Everyday Sunglasses Frame",
          id: "668540567db56839146d6f8e",
        },
      ],
    },
  ];
  const [isVisible, setIsVisible] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const location = window.location;
  const dispatch = useDispatch();
  const user = useGetUser();
  const params = useParams();

  const [searchProductsVal, setSearchProductsVal] = useState(
    location?.pathname?.split("/")[1] === "search"
      ? location?.pathname?.split("/")[2]
      : ""
  );

  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (!location?.pathname.includes("search")) {
      setSearchProductsVal("");
    }
  }, [location.pathname]);
  const myBagData = useSelector((state) => state?.myBagData?.items);

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsVisible(false);
      return;
    }
  }, [location]);
  useEffect(() => {
    function handleScroll() {
      if (location.pathname === "/login") {
        setIsVisible(false);
        return;
      }
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const AccountIcon = () => {
    return (
      <Link
        to={`${user?.customer ? "/profile-details#your-account" : "/login"}`}
        className="material-symbols-outlined link"
      >
        <img src={PERSON_IC} alt="" className="w-9" />
      </Link>
    );
  };
  const FavouriteIcon = () => {
    return (
      <Link to="/favourite" className="material-symbols-outlined link relative">
        <img src={LIKE__IC} alt="" className="w-8" />
        {wishlistData?.length !== 0 && (
          <div className="absolute -top-1 right-1.5 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
            <span className="text-white text-[14px] mt-0.5">
              {wishlistData?.length > 9 ? "9+" : wishlistData?.length}
            </span>
          </div>
        )}
      </Link>
    );
  };

  const navigate = useNavigate();
  const searchProductsHandle = (value) => {
    setSearchProductsVal(value);
  };

  const searchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
      } else {
        navigate(`/search/${searchProductsVal}`);
      }
    }
  };

  const mobileSearchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      } else {
        navigate(`/search/${searchProductsVal}`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    }
  };

  const [showBrowse, setShowBrowse] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(null);

  return (
    <>
      <Breakpoint large up>
        <div className="header p-f t-0 l-0 w-100 ">
          {/* <div className="top-links">
            <Link to="/aboutus">About Us</Link>
            <Link to="/search">Browse Collections</Link>
            <Link to="/lens">Buy Lenses</Link>
            <Link to="/">Home Tryon</Link>
            <Link to="/search">Offers %</Link>
            <Link to="/blog">Blogs</Link>
            <Link to="/become-retailer">For Business</Link>
          </div> */}
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child">
              <div className="d-flex a-c">
                <INPUT
                  isNotValidate
                  placeHolder={"Search"}
                  className={"py-0 px-0 w-full  border-0"}
                  mainClass={"p-0 m-0 w-[200px] "}
                  val={searchProductsVal}
                  onChange={(s) => searchProductsHandle(s.target?.value)}
                  onKeyDown={(e) => searchNavigationHandle(e)}
                  lComp={
                    <div className="material-symbols-outlined text-2xl me-1">
                      <img src={SEARCH_IC} alt="" className="w-8" />
                    </div>
                  }
                />
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="d-flex j-e a-c header-right-icons">
                <FavouriteIcon />
                <div className="vertical-rule"></div>
                <AccountIcon />
                <div className="vertical-rule"></div>
                <button
                  // disabled={myBagData?.length === 0}
                  className="material-symbols-outlined relative"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img src={BAG_IC} alt="" className="w-8" />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className="header-bottom relative justify-center flex bg-1"
            style={{
              display: isVisible ? "flex" : "none",
              transition: "0.5s all ease",
            }}
          >
            <div
              onMouseLeave={() => setShowBrowse(false)}
              onMouseEnter={() => {
                setShowBrowse(true);
                setShowSubCategory(catData[0]);
              }}
              className="w-fit flex justify-between items-center"
            >
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setShowSubCategory(null);
                }}
                onClick={() => {
                  navigate(`/aboutus`);
                }}
                className="items mont-regular text-nowrap"
              >
                About Us
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(true);
                  setShowSubCategory(catData[0]);
                }}
                className="items mont-regular text-nowrap ms-10"
              >
                Browse Collection
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setShowSubCategory(null);
                }}
                onClick={() => {
                  navigate(`/home-tryon`);
                }}
                className="items mont-regular text-nowrap ms-10"
              >
                Home Try on
              </div>

              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setShowSubCategory(null);
                }}
                onClick={() => {
                  navigate(`/search`);
                }}
                className="items mont-regular text-nowrap color-2 ms-10"
              >
                Offers %
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setShowSubCategory(null);
                }}
                onClick={() => {
                  navigate(`/become-retailer`);
                }}
                className="items mont-regular text-nowrap ms-10"
              >
                For Business
              </div>
              {showBrowse && (
                <div className="absolute xl:w-auto lg:w-[75%] emd:w-full cursor-pointer bg-white flex left-[50%] top-[4.2rem] -translate-x-1/2 shadow-md">
                  <div className="flex flex-col">
                    {catData?.map((item, index) => {
                      return (
                        <span
                          key={index}
                          // onClick={() => {
                          //   navigate(`/categories/${item?.id}`);
                          //   setShowBrowse(false);
                          // }}
                          className={` ${
                            showSubCategory?.id === item?.id
                              ? "bg-gray text-primary"
                              : ""
                          } py-3 px-4 relative flex items-start w-full cursor-pointer text-nowrap mb-2`}
                          // onClick={() => setShowSubCategory(item)}
                          onMouseEnter={() => setShowSubCategory(item)}
                        >
                          {item?.title}
                        </span>
                      );
                    })}
                  </div>
                  {showSubCategory && (
                    <div className="cursor-pointer xl:w-auto lg:w-full emd:w-full bg-gray grid grid-cols-3 auto-rows-auto lg:gap-4 xl:gap-4 min-h-72 items-start overflow-hidden px-8 py-4 shadow-md">
                      {showSubCategory?.subCategory?.map((subItem, index) => {
                        return (
                          <div
                            key={index}
                            className="xl:w-[160px] lg:w-[160px] flex text-center flex-col items-center justify-center"
                            onClick={() => {
                              navigate(`/categories/${subItem?.id}`);
                              setShowBrowse(false);
                            }}
                          >
                            <div className="w-full bg-white flex items-center justify-center">
                              <img
                                src={subItem?.img}
                                alt=""
                                className="w-24 h-24 object-contain"
                              />
                            </div>
                            <span className="text-xs mt-3 px-1">
                              {subItem?.title}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Breakpoint>

      {/* =========================== FOR MOBILE AND TABLET =============================  */}

      <Breakpoint
        className={`${searchToggle ? "z-9999999 bg-green" : ""}`}
        medium
        down
      >
        <div className={`header p-f t-0 l-0 w-100`}>
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child">
              <div className="d-flex a-c">
                <div
                  className="material-symbols-outlined"
                  onClick={() => setSidebarToggle(true)}
                >
                  <img src={MENU_IC} alt="" className="w-8" />
                </div>
                <div className="vertical-rule"></div>
                <div
                  className="material-symbols-outlined"
                  onClick={() => {
                    setSearchToggle(true);
                    setSearchProductsVal("");
                  }}
                >
                  <img
                    src={!searchToggle ? SEARCH_IC : CLOSE_IC}
                    alt=""
                    className="w-8"
                  />
                </div>
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="d-flex j-e a-c header-right-icons">
                <AccountIcon />
                <div className="vertical-rule"></div>
                <button
                  className="material-symbols-outlined relative me-2"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img src={BAG_IC} alt="" className="w-8" />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          {searchToggle ? (
            <div className="absolute bg-white p-5 top-16 w-full z-9999 flex items-center justify-center">
              <INPUT
                placeHolder="Search..."
                val={searchProductsVal}
                onChange={(s) => setSearchProductsVal(s?.target?.value)}
                onKeyDown={(e) => mobileSearchNavigationHandle(e)}
              />
            </div>
          ) : (
            <></>
          )}
          {searchToggle && (
            <div
              className={`fixed top-0 left-0 w-full h-full transition-all duration-75 z-999 ${
                searchToggle ? "opacity-50 " : ""
              }`}
              style={{
                background:
                  "linear-gradient(to bottom, transparent, #0000004c)",
              }}
              onClick={() => {
                setSearchToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
            ></div>
          )}
        </div>
      </Breakpoint>
    </>
  );
}
export default Header;

{
  /* {headerLabelsData?.labels?.homepage_category_to_show &&
              [...headerLabelsData?.labels?.homepage_category_to_show]
                .reverse()
                ?.splice(1)
                ?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/categories/${item?.id}`);
                      }}
                      key={index}
                      className="items mont-regular text-nowrap"
                    >
                      {item?.name}
                    </div>
                  );
                })} */
}
