// recentProductsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentProducts: [],
};

const recentProductsSlice = createSlice({
  name: "recentProducts",
  initialState,
  reducers: {
    addRecentProduct: (state, action) => {
      const newProduct = action.payload;

      // Prevent adding undefined products
      if (!newProduct || !newProduct) return;

      // Check if product is already in the list
      const existingIndex = state.recentProducts.findIndex(
        (product) => product?._id === newProduct?._id
      );

      if (existingIndex !== -1) {
        // Move existing product to the front of the list
        state.recentProducts.splice(existingIndex, 1);
      }

      // Add the product to the start of the list
      state.recentProducts.unshift(newProduct);

      // Limit the list to 12 items
      if (state.recentProducts.length > 12) {
        state.recentProducts.pop();
      }

      // Prevent adding undefined or null products
    },
  },
});

export const { addRecentProduct } = recentProductsSlice.actions;
export default recentProductsSlice.reducer;
