import { useNavigate } from "react-router-dom";
import "./netreBluerim.scss";

import React from "react";

export default function NetreBlurim({ homeData }) {
  const navigate = useNavigate();
  return (
    <div className="NetreBlueRim_Container">
      <div
        className="NetreBlueRim-Section_Left"
        style={{
          backgroundImage: `url('${homeData?.two_branding_banners?.banners[0]?.background}')`,
        }}
      >
        <div className="Content_Container">
          <div className="Content-1st">
            <div className="Netre_Logo_left">
              <img
                src={homeData?.two_branding_banners?.banners[0]?.title_image}
                alt="logo"
                height="100%"
                width="100%"
              />
            </div>
            <div className="Content-Para">
              {homeData?.two_branding_banners?.banners[0]?.description}
            </div>
            <div
              onClick={() =>
                navigate(homeData?.two_branding_banners?.banners[0]?.redirect)
              }
              className="Discover-btn"
            >
              {homeData?.two_branding_banners?.banners[0]?.button_text}
            </div>
          </div>
        </div>
        <div className="Netre_glassImg_left">
          <img
            src={homeData?.two_branding_banners?.banners[0]?.frame_image}
            alt="glassImg"
            height="100%"
            width="100%"
          />
        </div>
      </div>
      <div
        className="NetreBlueRim-Section_Right"
        style={{
          backgroundImage: `url('${homeData?.two_branding_banners?.banners[1]?.background}')`,
        }}
      >
        <div className="Content_Container">
          <div className="Content-1st">
            <div className="Netre_Logo_right">
              <img
                src={homeData?.two_branding_banners?.banners[1]?.title_image}
                alt="logo"
                height="100%"
                width="100%"
              />
            </div>
            <div className="Content-Para">
              {homeData?.two_branding_banners?.banners[1]?.description}
            </div>
            <div
              onClick={() =>
                navigate(homeData?.two_branding_banners?.banners[1]?.redirect)
              }
              className="Discover-btn"
            >
              {homeData?.two_branding_banners?.banners[1]?.button_text}
            </div>
          </div>
        </div>
        <div className="Netre_glassImg_right">
          <img
            src={homeData?.two_branding_banners?.banners[1]?.frame_image}
            alt="glassImg"
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}
