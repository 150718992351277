import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      {/* Security and Privacy */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Security and Privacy</h2>
        <p className="mb-4">
          At Netré, we understand the importance of safeguarding your personal
          information. Your trust is invaluable to us, and we are committed to
          using your information responsibly. Here's how we ensure the
          confidentiality, integrity, and security of your data:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <strong>Information You Provide:</strong> When you interact with our
            website, you may provide us with certain information. We do not
            store any personal sensitive information on our servers; it remains
            with you on your system. This information helps us respond to your
            requests, customize future transactions, improve our database, and
            communicate with you.
          </li>
          <li>
            <strong>Automatic Information:</strong> We receive and store certain
            types of information when you interact with us. Our server logs your
            activities for diagnostic and analytical purposes, but we only
            maintain your IP address for logging purposes.
          </li>
          <li>
            <strong>Email Communications:</strong> To enhance the utility of our
            emails, we may request confirmation when you open an email from us,
            if your computer supports such capabilities.
          </li>
          <li>
            <strong>Sharing of Information:</strong> We may share customer
            information related to transactions with other businesses involved
            in those transactions.
          </li>
          <li>
            <strong>Business Transfers:</strong> As we grow, we may merge with
            or be acquired by another company. In such cases, customer
            information is typically transferred as a business asset, and we'll
            ensure the protection of your information as per our policies.
          </li>
          <li>
            <strong>Protection of Company and Others:</strong> We may release
            account and personal information when necessary to comply with the
            law, enforce our terms and agreements, or protect the rights,
            property, or safety of our users and others.
          </li>
        </ul>
        <p className="mt-4">
          By using our website, you accept the practices described in this
          Privacy Policy. We're proud of our privacy practices and the strength
          of our site security, and we're committed to providing you with the
          best service while respecting your privacy.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
