import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Account from "./Account";
import OrderHistory from "./OrderHistory";
import Coupons from "./Coupons";
import Payment from "./Payment";
import ReturnOrder from "./ReturnOrder";
import CancelOrder from "./CancelOrder";
import ExchangeOrder from "./ExchangeOrder";
import TrackOrder from "./TrackOrder";
import { CALL_IC, MAIL_IC } from "../../../assets";
import useGetUser from "../../../hooks/useGetUser";

const ProfileDetails = () => {
  let user = localStorage.getItem("user");
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  useEffect(() => {
    if (!user) {
      localStorage.removeItem("user");
      navigate("/login");
    }
  }, [user]);

  return (
    <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
      <div className="lg:w-[20%] h-[100dvh]] edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
        <a
          href="#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Your Account
        </a>
        <a
          href="#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Order History
        </a>
        <a
          href="#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Coupons
        </a>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>

        <span className="text-xl mt-10">Need Assistance ?</span>
        <div className="flex flex-col items-start">
          <Link
            to={"/contact-us"}
            className="underline mt-3 cursor-pointer select-none"
          >
            Contact Us
          </Link>
          <div className="flex items-center mt-3">
            <div className="material-symbols-outlined ">
              <img src={CALL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2">18002028223 </span>
          </div>
          <div className="flex items-center">
            <div className="material-symbols-outlined ">
              <img src={MAIL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2">
              <a href="mailto:info@netre.in" className="email-link">
                help@netre.in
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap hide_scrollbar">
        <a
          href="#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  select-none cursor-pointer`}
        >
          Your Account
        </a>
        <a
          href="#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Order History
        </a>
        <a
          href="#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Coupons
        </a>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>
      </div>

      <div className="lg:w-[80%] emd:w-full small:w-full p-6">
        {hash === "#your-account" ? (
          <Account />
        ) : hash === "#order-history" ? (
          <OrderHistory />
        ) : (
          (hash === "#coupons" && <Coupons />) || <Account />
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;
