import React from "react";
import { useNavigate } from "react-router-dom";

const Tryon1BlockNew = ({ homeData }) => {
  const navigate = useNavigate();
  console.log("homeData", homeData);
  return (
    <div
      className="tryon_new gap-5 grid grid-cols-2 lg:h-[100vh] emd:h-[100vh] small:h-[80vh] mb-5"
      style={{
        width: "100%",
        maxWidth: "100%",
        position: "relative",
        zIndex: 1,
      }}
    >
      {homeData?.grid_banners?.map((item, index) => {
        return (
          <div
            key={index}
            className="w-full h-full overflow-hidden bg-primary bg-opacity-35 relative"
          >
            <img
              src={homeData?.main_banner_images[0]?.mobile}
              alt=""
              className="bg-gray w-full h-full object-cover"
            />
            <div
              className="w-fit h-fit px-3 py-2 border cursor-pointer absolute bg-white top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              onClick={() => navigate(homeData?.redirect)}
            >
              {homeData?.grid_banners_button_text}
            </div>
          </div>
        );
      })}
      {/* <div className="Plus_Block">
        <div
          className="Buy1Get1_btn"
          onClick={() =>
            navigate(homeData?.grid_banners?.left_banner?.redirect)
          }
        >
          {homeData?.grid_banners?.left_banner?.button_text}
        </div>
      </div> */}
    </div>
  );
};

export default Tryon1BlockNew;
