import React, { useEffect, useState } from 'react';
import { useGetFiltersQuery } from '../../../redux/slices/homeSlice';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Accordion from '../ShoppingBag/Accordion';

export const FilterSidePanel = ({ showFilter, showFilterHandler, category, setSortBy , totalDocs, setAttributeFilters}) => {
  const params = useParams();
  const sortby = [
    { name: 'Newest Arrivals', value: 'date-added-desc' },
    { name: 'Price- High to Low', value: 'high' },
    { name: 'Price- Low to High', value: 'low' },
  ];
  const [selectedSortby, setSelectedSortby] = useState({});
  useEffect(() => {
    setSelectedSortby(sortby[0]);
  }, []);
  useEffect(() => {
    setSortBy(selectedSortby);
  }, [selectedSortby]);
  const { data: filtersData, isLoading, isFetching, isSuccess, isError, error } = useGetFiltersQuery({
    queryParams: category
    ? `category=${category}`
    : `${
        params?.searchProductParams
          ? `title=${params?.searchProductParams}`
          : ""
      }`,
  });


  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
    },
    onSubmit: values => {
      // console.log(values);
    },
    validate: (values) => {
      let errors = {};
      return errors;
    },
  });

  const setAsMyFilter = () => {
    console.log(filterFormik.values);
  }

  // json data for filters using filterFormik.values making key value pairs
  useEffect(() => {
    let filters = {};
    Object.keys(filterFormik.values).forEach((key) => {
      if (filterFormik.values[key].length > 0) {
        filters[key] = filterFormik.values[key].join(',');
      }
    });
    // console.log(filters);
    setAttributeFilters(filters);
  }, [filterFormik.values]);  

  return (
    <div className={`filter-side-panel ${showFilter ? 'show' : ''}`}>
      <div className="overlay" onClick={showFilterHandler}></div>
      <div className="panel">
        <div className="filter-body">
          <div className="sortby">
            <div className="title">Sort By</div>
            {sortby.map((item, index) => (
              <div key={index}
                className={item.name === selectedSortby.name ? "sortby-item sortby-item-active" : "sortby-item"}
                onClick={() => setSelectedSortby(item)}
              >{item.name}</div>
            ))}
          </div>
          <div className="filterby">
            <div className="d-flex j-sb">
              <div className="title">Filter By</div>
              <div className="clear-all" 
              onClick={()=>{
                filterFormik.resetForm({ values: filterFormik.initialValues });
                setTimeout(filterFormik.handleSubmit(), 0);
              }}>Clear all</div>
            </div>
            {/* <div className="set-my-filter" onClick={setAsMyFilter}>Set as my filter</div> */}
            <div className="attribute-filters">
              {filtersData?.attribute_filters?.map((item, index) => (
                  <Accordion
                    key={index}
                    title={<div className="title">{item.attributeName}</div>}
                    content={
                      <>
                      <div className="filter-items">
                        {item?.attributeValues.map((option, index) => {
                          return (
                            <div className='check-container d-flex' key={`${index}-${option}`}>
                              <input type="checkbox" 
                              id={`${item.attributeName}-${option}`}
                              name={item.attributeName}
                              onChange={(e) => {
                                filterFormik.handleChange(e);
                                setTimeout(filterFormik.handleSubmit(), 0);
                              }}
                              value={option}
                              onBlur={filterFormik.handleBlur}
                              checked={filterFormik.values[item.attributeName]?.includes(option) || false}
                              className='accent-black'
                              />
                              <label 
                              className='ps-3 flex-grow cursor-pointer align-middle'
                              htmlFor={`${item.attributeName}-${option}`}>{option}</label><br></br>
                            </div>
                          );
                        })}
                      </div>
                      </>
                    }
                  />
              ))}
            </div>
          </div>
        </div>
        <div className="results-btn"
          onClick={() => {
            filterFormik.handleSubmit();
            showFilterHandler();
          }}
        >{totalDocs} RESULTS FOR YOUR SEARCH</div>
      </div>
    </div>
  );
};
