import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { homeSlice } from "../slices/homeSlice";
import wishlistReducer from "../slices/wishlistSlice";
import myBagReducer from "../slices/myBagSlice";
import myBagToggleReducer from "../slices/myBagToggleSlice";
import recentProductsReducer from "../slices/recentProductsSlice";
import homeTryOnReducer from "../slices/homeTryonSlice";

const rootReducer = combineReducers({
  wishlist: wishlistReducer,
  myBagData: myBagReducer,
  homeTryOnData: homeTryOnReducer,
  myBagToggle: myBagToggleReducer,
  recentProducts: recentProductsReducer,
  [homeSlice.reducerPath]: homeSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(homeSlice.middleware),
});
setupListeners(store.dispatch);
