import "./ProductPage.scss";
import "flickity/dist/flickity.min.css";

import React, { useEffect, useRef, useState } from "react";

import {
  useAddToCustomerBagMutation,
  useAddToCustomerHomeTryOnDataMutation,
  useAddToWishlistMutation,
  useGetProductDataQuery,
  useRemoveFromWishlistMutation,
} from "../../../../redux/slices/homeSlice";
import { useNavigate, useParams } from "react-router-dom";
import useGetUser from "../../../../hooks/useGetUser";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addToMyBag } from "../../../../redux/slices/myBagSlice";
import BTN from "../../../Master/BTN";
import Slider from "react-slick";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { addRecentProduct } from "../../../../redux/slices/recentProductsSlice";
import LtoRScrollListing from "../../../Master/LtoRScrollListing";
import {
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_LEFT,
  ARROW_KEYWORD_RIGHT,
  ARROW_KEYWORD_UP,
  CLOSE_BLACK_IC,
  CONTENT_COPY_IC,
  FB_IC,
  FN_FB_IC,
  FN_WHATSAPP_IC,
  FN_X_IC,
  IOS_SHARE_IC,
  NETRE_LOGO_PATTERN,
} from "../../../../assets";
import LazyImageLoader from "../../../Master/LazyImageLoader";
import MODEL from "../../../Master/MODEL";

// import "flickity-as-nav-for";

export default function ProductPage({ blueCutLensIdDataLabel }) {
  const user = useGetUser();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isShareToggle, setIsShareToggle] = useState(false);
  const [isView, setIsView] = useState("photo");
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showImage, setShowImage] = useState("");
  const [Banner, setBanner] = useState(NETRE_LOGO_PATTERN);
  const [ProductBanner, setProductBanner] = useState(
    "/Image_27-removebg-preview.png"
  );
  const { id } = useParams();
  const {
    data: productData,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetProductDataQuery({ id: id });
  const attributes = productData?.product.attributes?.[0] || null;
  useEffect(() => {
    if (isSuccess) {
      setShowImage(
        productData.product.image[0].replace(
          "upload/",
          "upload/if_w_gt_800/w_800/if_end/"
        )
      );
    }
  }, [productData, isSuccess]);
  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);
  useEffect(() => {
    if (wishlistData) {
      if (productData?.product._id) {
        const found = wishlistData.find(
          (element) => element._id === productData.product._id
        );
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, productData]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!productData.product._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: productData.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: productData.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: productData?.product?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(
            `${productData?.product?.name} added to bag successfully.`
          );
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(productData?.product));
      toast.success(`${productData?.product?.name} added to bag successfully.`);
    }
  };

  // ====================== ADD TO HOME TRY ON ================================

  const [addToCustomerHomeTryOnData] = useAddToCustomerHomeTryOnDataMutation();

  const addToHomeTryOnHandel = () => {
    if (loggedIn) {
      addToCustomerHomeTryOnData({
        body: {
          products: [
            {
              product_id: productData?.product?._id,
              variant: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(
            `${productData?.product?.name} added to home try-on successfully.`
          );
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: { isHomeTryOn: true },
      });
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // attributes from productData
  const options = productData?.product?.options?.[0] || null;
  const variants = productData?.product?.variants;
  const [selectedVariant, setSelectedVariant] = useState({});
  let sliderRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const handleOptionChange = (option) => {
    // find the optionName in selectedOption and update the value
    const optionName = option.optionName;
    const optionValue = option.optionValue;
    const newSelectedOption = selectedOption.map((option) => {
      if (Object.keys(option)[0] === optionName) {
        return {
          [optionName]: optionValue,
        };
      }
      return option;
    });
    setSelectedOption(newSelectedOption);
    sliderRef.slickGoTo(0);
  };

  // make an array with the number of options with key id pairs
  const optionArray = [];
  useEffect(() => {
    if (options) {
      Object.keys(options).map((key) => {
        const option = {
          [key]: options[key][0].optionValue,
        };
        optionArray.push(option);
      });
      setSelectedOption(optionArray);
    }
  }, [options]);

  const [clusteredProducts, setClusteredProducts] = useState([]);
  useEffect(() => {
    if (productData) {
      if (productData?.product) {
        if ("clusteredProducts" in productData.product) {
          // const tempColors = [];
          // productData.product.clusteredProducts.map((product) => tempColors.push(product.code));
          // setColors(tempColors);
          setClusteredProducts(productData.product.clusteredProducts);
        }
      }
    }
  }, [productData]);

  const scrollTBRef = useRef(null);
  const scrollLRRef = useRef(null);

  const scrollTopToBottom = (direction) => {
    if (scrollTBRef.current) {
      if (direction === "up") {
        scrollTBRef.current.scrollBy({ top: -100, behavior: "smooth" });
      } else {
        scrollTBRef.current.scrollBy({ top: 100, behavior: "smooth" });
      }
    }
  };

  const scrollLeftToRight = (direction) => {
    if (scrollLRRef.current) {
      if (direction === "left") {
        scrollLRRef.current.scrollBy({ left: -100, behavior: "smooth" });
      } else {
        scrollLRRef.current.scrollBy({ left: 100, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (productData) {
      dispatch(addRecentProduct(productData?.product));
    }
  }, [productData, dispatch]);

  const recentProducts = useSelector(
    (state) => state.recentProducts.recentProducts
  );

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const handleSlideChange = (index) => {
    if (sliderRef) {
      sliderRef.slickGoTo(index);
      setSelectedImageIndex(index);
    }
  };

  // =================== ADD CART HANDLE =================
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: productData?.product?._id },
      });
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  //=============== HANDLE COPY PRODUCT URL ================

  const handleCopyProductUrlClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((err) => {
        toast.success("Failed to copy text: ");
      });
  };

  // ====================== ADD ZERO POWER LENS HANDLE ================================

  const addZeroPowerLensHandle = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: productData?.product?._id,
              quantity: 1,
              variant: [],
              zeroPower: true,
              lens: [
                {
                  lens_id: blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                  addons: [],
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: {
          forZeroPowerLensId: productData?.product?._id,
          isOnlyLens: false,
        },
      });
    }
    setLensAddModelToggle(false);
  };

  return (
    <div className="tw_product_main w-full h-full bg-[#F7F7F8] overflow-hidden flex flex-col items-center">
      <div className="w-full flex justify-between py-5 px-10">
        <div className="h-[450px] w-[10%] overflow-hidden lg:flex emd:hidden small:hidden flex-col items-center">
          {productData?.product?.image?.length > 4 && (
            <button
              className="w-8 h-8 rounded-full bg-white mb-3 flex items-center justify-center text-center"
              onClick={() => scrollTopToBottom("up")}
            >
              <div className="material-symbols-outlined clear_ic">
                <img src={ARROW_KEYWORD_UP} alt="" className="w-9" />
              </div>
            </button>
          )}
          <div
            ref={scrollTBRef}
            className="max-h-full overflow-y-auto overflow-x-hidden lg:flex emd:hidden small:hidden flex-col hide_scrollbar"
          >
            {productData?.product?.image?.length > 1 && (
              <div className="Clustered-products">
                {productData?.product?.image?.map((product, index) => {
                  return (
                    <div
                      key={index}
                      className={` ${index === 0 ? "" : "mt-1"}
                      ${
                        selectedImageIndex === index
                          ? "border border-primary"
                          : "border border-white"
                      }
                       Clustered-product pointer min-w-[80px] max-w-[80px] min-h-[80px] max-h-[80px] flex items-center justify-center`}
                      onClick={() => handleSlideChange(index)}
                    >
                      <img
                        src={product?.replace(
                          "upload/",
                          "upload/if_w_gt_200/w_200/if_end/"
                        )}
                        alt="Product"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {productData?.product?.image?.length > 4 && (
            <button
              className=" w-8 h-8 rounded-full bg-white mt-3 flex items-center justify-center text-center"
              onClick={() => scrollTopToBottom("down")}
            >
              <div className="material-symbols-outlined clear_ic ">
                <img src={ARROW_KEYWORD_DOWN} alt="" className="w-9" />
              </div>
            </button>
          )}
        </div>

        <div className="lg:h-[60%] emd:h-[70%] small:h-[70%] lg:w-[70%] emd:w-full small:w-full flex flex-col items-center justify-between">
          <div className="w-full lg:hidden emd:flex small:flex justify-between items-center">
            {wishListed ? (
              <svg
                onClick={handleWishlist}
                className="pointer filled"
                viewBox="0 -960 960 960"
                height="24px"
                fill="#AC772B"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
                  transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
                />
              </svg>
            ) : (
              <svg
                onClick={handleWishlist}
                className="pointer"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z" />
              </svg>
            )}
            <div className="w-fit flex items-center">
              {/* <button className="border border-primary text-primary flex items-center w-fit justify-center py-1 px-4">
                <div className="material-symbols-outlined clear_ic text-2xl text-primary">
                  center_focus_strong
                </div>
                <span className="text-primary font-semibold text-base mt-1 ms-1 text-nowrap">
                  Try On
                </span>
              </button> */}
              <button
                onClick={() => setIsShareToggle(!isShareToggle)}
                className="rounded-full w-8 h-8 flex items-center justify-center ms-2 bg-primary"
              >
                <div className="material-symbols-outlined clear_ic">
                  <img src={IOS_SHARE_IC} alt="" className="w-6" />
                </div>
              </button>
            </div>
          </div>
          <div className="w-full flex mt- relative flex-col items-center">
            {isView === "photo" ? (
              <div className="w-full flex relative items-center">
                <img
                  src={ARROW_KEYWORD_LEFT}
                  onClick={() => {
                    document.querySelector(".slick-arrow.slick-prev").click();
                  }}
                  alt=""
                  className="w-9 rounded-full h-fit bg-white cursor-pointer"
                />
                <Slider
                  {...settings}
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  className="w-full h-full flex flex-col overflow-hidden justify-center items-center"
                >
                  {productData?.product?.image?.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={image.replace(
                          "upload/",
                          "upload/if_w_gt_800/w_800/if_end/"
                        )}
                        alt="Product"
                        className="w-full h-[460px] m-auto object-contain"
                      />
                    );
                  })}
                </Slider>
                <img
                  src={ARROW_KEYWORD_RIGHT}
                  onClick={() => {
                    document.querySelector(".slick-arrow.slick-next").click();
                  }}
                  alt=""
                  className="w-9 rounded-full h-fit bg-white cursor-pointer"
                />
              </div>
            ) : (
              <iframe
                id="threeD-view-iframe-container"
                className="lg:aspect-[16/9] lg:w-auto emd:w-full small:w-full lg:h-[460px] emd:h-[460px] small:h-[300px] object-contain my-auto select-none"
                draggable={false}
                title="3D-view"
                src={productData?.product?.url_360}
              />
            )}

            {productData?.product?.url_360 && (
              <div className="mt-4 px-2 py-1.5 bg-white flex items-center">
                <div
                  onClick={() => setIsView("photo")}
                  className={`${
                    isView === "photo"
                      ? "bg-secondary text-white"
                      : "bg-transparent text-secondary"
                  } w-20 text-center flex items-center justify-center cursor-pointer`}
                >
                  Photo
                </div>
                <div
                  onClick={() => setIsView("360")}
                  className={`${
                    isView === "360"
                      ? "bg-secondary text-white"
                      : "bg-transparent text-secondary"
                  }  ms-2 w-20 text-center flex items-center justify-center cursor-pointer`}
                >
                  360°
                </div>
              </div>
            )}
          </div>

          {clusteredProducts?.length > 1 && (
            <div className="w-fit lg:hidden emd:flex small:flex mb-5 mt-5 justify-between items-center">
              <button
                className="min-w-8 min-h-8 max-w-8 max-h-8 rounded-full bg-white me-3 flex items-center justify-center text-center"
                onClick={() => scrollLeftToRight("left")}
              >
                <div className="material-symbols-outlined clear_ic ">
                  <img src={ARROW_KEYWORD_LEFT} alt="" className="w-8" />
                </div>
              </button>
              <div
                ref={scrollLRRef}
                className={`${
                  clusteredProducts?.length >= 3
                    ? "emd:min-w-[75%] emd:max-w-[75%] small:min-w-[220px] small:max-w-[220px]"
                    : "w-fit"
                }  overflow-x-auto overflow-y-hidden lg:hidden emd:flex small:flex hide_scrollbar`}
              >
                {clusteredProducts?.length > 1 && (
                  <div className="w-full flex items-center justify-start">
                    {clusteredProducts?.map((product, index) => {
                      return (
                        <div
                          key={index}
                          className={` ${index === 0 ? "" : "ml-1"}
                      ${
                        productData?.product?._id === product.product_id ||
                        productData?.product?.product_id === product.product_id
                          ? "border border-primary"
                          : "border border-white"
                      } min-w-[80px] max-w-[80px] min-h-[80px] max-h-[80px] flex items-center justify-center Clustered-product pointer`}
                          onClick={() =>
                            navigate(`/products/${product.product_id}`)
                          }
                        >
                          <img
                            src={product?.image?.[0]?.replace(
                              "upload/",
                              "upload/if_w_gt_200/w_200/if_end/"
                            )}
                            alt="Product"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <button
                className="min-w-8 min-h-8 max-w-8 max-h-8 rounded-full bg-white ms-3 flex items-center justify-center text-center"
                onClick={() => scrollLeftToRight("right")}
              >
                <div className="material-symbols-outlined clear_ic">
                  <img src={ARROW_KEYWORD_RIGHT} alt="" className="w-8" />
                </div>
              </button>
            </div>
          )}
        </div>

        <div className="relative h-48 w-[10%] lg:flex emd:hidden small:hidden flex-col items-end">
          {/* <button className="border border-primary text-primary flex items-center w-full justify-center py-1 px-4">
            <div className="material-symbols-outlined clear_ic text-2xl text-primary">
              center_focus_strong
            </div>
            <span className="text-primary font-semibold text-base mt-1 ms-1 text-nowrap">
              Try On
            </span>
          </button> */}
          <button
            onClick={() => setIsShareToggle(!isShareToggle)}
            className="rounded-full w-8 h-8 bg-primary flex items-center justify-center"
          >
            <div className="material-symbols-outlined clear_ic ">
              <img src={IOS_SHARE_IC} alt="" className="w-6" />
            </div>
          </button>
        </div>
      </div>
      <div className="lg:w-[70%] emd:w-full small:w-full p-4 bg-white flex items-center justify-between">
        <div className="lg:w-[20%] emd:w-[50%] small:w-[50%]  flex flex-col items-start overflow-hidden">
          <span className="">{productData?.product?.title?.en}</span>
          {/* <span className="">
            &#x20B9;&nbsp;{productData?.product?.prices?.price}
          </span> */}
          {productData?.product?.prices?.discount !== 0 ? (
            <div className="flex items-center">
              <div className="line-through text-light_secondary text-nowrap">
                {productData?.product?.prices?.originalPrice}
              </div>
              <div className="ms-2 text-nowrap mont-bold">
                ₹ {productData?.product?.prices?.price}
              </div>
            </div>
          ) : (
            <div className="mont-bold">₹ {productData?.product?.prices?.price}</div>
          )}
        </div>
        <div className="lg:w-[calc(100%-20%)] emd:w-[50%] small:w-[50%] lg:border-l emd:border-0 small:border-0  emd:border-l small:border-l lg:border-0 border-primary flex items-center lg:justify-between emd:justify-center small:justify-center">
          {wishListed ? (
            <svg
              onClick={handleWishlist}
              className="pointer filled lg:flex emd:hidden small:hidden ms-2"
              viewBox="0 -960 960 960"
              height="24px"
              fill="#AC772B"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
                transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
              />
            </svg>
          ) : (
            <svg
              onClick={handleWishlist}
              className="pointer lg:flex emd:hidden small:hidden ms-2"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z" />
            </svg>
          )}

          <div className="lg:flex emd:hidden small:hidden overflow-x-auto hide_scrollbar">
            {clusteredProducts?.length > 1 && (
              <div className="w-full grid grid-cols-4 gap-2 items-center justify-center ">
                {clusteredProducts?.map((product, index) => {
                  return (
                    <div
                      key={index}
                      className={`
                      ${
                        productData?.product?._id === product.product_id ||
                        productData?.product?.product_id === product.product_id
                          ? "border border-primary"
                          : "border border-white"
                      } min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] bg-[#F7F7F8] overflow-hidden flex items-center m-auto  rounded-md justify-center Clustered-product pointer relative`}
                      onClick={() =>
                        navigate(`/products/${product.product_id}`)
                      }
                    >
                      <img
                        src={product?.image?.[1]?.replace(
                          "upload/",
                          "upload/if_w_gt_200/w_200/if_end/"
                        )}
                        alt="Product"
                        // className="bg-red-400 absolute right-[40%] min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] object-contain"
                        className="absolute -translate-x-[50%] transform  scale-[2] object-contain"
                      />
                      {/* <div className="Clustered-product-name absolute bottom-0 -right-10">
                        {product?.name}
                      </div> */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="flex items-center lg:flex-row emd:flex-col-reverse small:flex-col-reverse">
            <BTN
              bordered
              className={
                "lg:w-fit emd:w-full small:w-full text-nowrap py-1 px-3 lg:text-base emd:text-sm small:text-[10px] small:mt-3 emd:mt-3 lg:mt-0 "
              }
              title={"HOME TRYON"}
              onP={() => addToHomeTryOnHandel()}
            />
            <BTN
              className={
                "lg:w-fit emd:w-full small:w-full text-nowrap py-1 px-3 lg:text-base emd:text-sm small:text-[10px] lg:ms-3 emd:ms-0 small:emd-0"
              }
              title={"ADD TO BAG"}
              onP={() => setLensAddModelToggle(true)}
            />
          </div>
        </div>
      </div>
      {productData?.product?.description?.en && (
        <div className="lg:w-[70%] emd:w-full small:w-full p-4 bg-white">
          {productData?.product?.description?.en}
        </div>
      )}
      <div className="lg:w-[70%] emd:w-full small:w-full p-4 bg-[#F7F7F8] drop-shadow-md flex lg:justify-end emd:justify-center small:justify-center">
        <span className="text-sm">Get it delivered by 4/5 days*</span>
      </div>

      <div className="mt-10 pt-10 w-full h-auto bg-1">
        <img
          src={Banner}
          alt="Img"
          className="w-full lg:h-auto emd:h-40 small:h-40 object-cover"
        />
      </div>
      <div className="pt-10 w-full flex items-center justify-between lg:flex-row emd:flex-col small:flex-col bg-1">
        <div className="lg:w-[40%] emd:w-full small:w-full h-[40dvh] p-4 overflow-hidden">
          { productData?.product?.image?.length > 1 && <img
            src={productData?.product?.image?.[1].replace(
              "upload/",
              "upload/if_w_gt_1000/w_1000/if_end/"
            )}
            alt={ProductBanner}
            style={{
              transform: "translateX(-50%)",
              transformOrigin: "left",
              scale: "2",
              objectFit: "contain",
            }}
            className="w-auto h-full object-cover"
          />}
        </div>
        <div className="flex flex-col items-start justify-start h-auto lg:px-0 emd:px-5 small:px-5 lg:mt-0 emd:mt-10 small:mt-10 lg:w-[50%] emd:w-full small:w-full">
          <span className="text-primary text-2xl">Product Details</span>
          <span className="text-sm mt-2">
            The Netre air glasses are a timeless sophisticated frame
          </span>
          <div className="lg:w-[90%] emd:w-full small:w-full border-b border-[#444444] mt-5 pb-2 flex items-center justify-between">
            <span>SKU :</span>
            <span>{productData?.product?.sku}</span>
          </div>

          {attributes &&
            Object.keys(attributes).map((key) => (
              <div key={key} className="w-full mt-5">
                <span className="info-grid-title mont-bold">{key}</span>
                {Object.keys(attributes[key]).map((key2) => (
                  // mark as selected when id value present in selectedOption with background color red
                  <div
                    className="lg:w-[90%] emd:w-full small:w-full border-b border-[#444444] mt-5 pb-2 flex items-center justify-between"
                    key={key2}
                  >
                    <div className="">
                      {attributes[key][key2].attributeName}
                    </div>
                    <div className="">
                      {attributes[key][key2].attributeValue}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <div className="w-full pt-5 pb-10 bg-1">
        {recentProducts?.length > 1 && (
          <LtoRScrollListing
            title={"Recently Viewed"}
            classForTitle="color-2"
            data={recentProducts}
            blueCutLensIdDataLabel={blueCutLensIdDataLabel}
          />
        )}
      </div>

      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setLensAddModelToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <BTN
              onP={() => addCartHandle(true)}
              title="Add Lens With Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 lg:text-base emd:text-sm small:text-sm"
              }
            />
            <BTN
              bordered
              onP={() => addZeroPowerLensHandle()}
              title="Add Lens With Zero Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 mt-5 lg:text-base emd:text-sm small:text-sm"
              }
            />
            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              className="text-primary cursor-pointer text-center"
              onClick={() => addCartHandle(false)}
            >
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>

      {/* =================== SHARE PRODUCT MODEL =============== */}
      <MODEL
        setShowModel={setIsShareToggle}
        showModel={isShareToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Share Frame</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setIsShareToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <div className="w-full flex items-center justify-center">
              <WhatsappShareButton url={`${window.location.href}`}>
                <img
                  src={FN_WHATSAPP_IC}
                  alt=""
                  className="bg-gray p-2 rounded w-10 cursor-pointer"
                />
              </WhatsappShareButton>
              <FacebookShareButton url={`${window.location.href}`}>
                <img
                  src={FN_FB_IC}
                  alt=""
                  className="bg-gray p-2 rounded w-10 ms-4 cursor-pointer"
                />
              </FacebookShareButton>
              <TwitterShareButton url={`${window.location.href}`}>
                <img
                  src={FN_X_IC}
                  alt=""
                  className="bg-gray p-2 rounded w-10 ms-4 cursor-pointer"
                />
              </TwitterShareButton>
            </div>
            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              onClick={() => {
                handleCopyProductUrlClick(`${window.location.href}`);
                setIsShareToggle(false);
              }}
              className="text-primary cursor-pointer text-center flex items-center justify-center"
            >
              Copy Link <img src={CONTENT_COPY_IC} alt="" className="ms-2" />
            </span>
          </div>
        </div>
      </MODEL>
    </div>
  );
}
