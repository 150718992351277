import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useCancelOrderMutation,
  useGetOrderDetailsDataQuery,
} from "../../../redux/slices/homeSlice";
import BTN from "../../Master/BTN";
import { toast } from "react-toastify";
import { ARROW_BACK_BLACK_IC, CALL_IC, MAIL_IC } from "../../../assets";

const CancelOrder = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();

  const user = localStorage.getItem("user");
  const [isSelected, setIsSelected] = useState("none"); // shopping_details, payment_details

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const {
    data: customerOrderDetailsData,
    isSuccess: customerOrderDetailsDataIsSuccess,
    isLoading: customerOrderDetailsDataIsLoading,
    isError: customerOrderDetailsDataIsError,
    error: customerOrderDetailsDataError,
  } = useGetOrderDetailsDataQuery({
    user: user,
    orderId: params?.cancelOrderId,
  });

  // estimated date is the date of order + 7 days
  const estimatedDeliveryDate = new Date(customerOrderDetailsData?.updatedAt);
  estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
  const options = { year: "numeric", day: "numeric", month: "numeric" };
  const formattedEstimatedDeliveryDate =
    estimatedDeliveryDate.toLocaleDateString("en-US", options);

  // Formate Date
  const formateOrderDate = new Date(customerOrderDetailsData?.updatedAt);
  formateOrderDate.setDate(formateOrderDate.getDate());
  const formattedOrderDate = formateOrderDate.toLocaleDateString(
    "en-US",
    options
  );

  const [cancelOrder] = useCancelOrderMutation();

  const cancelOrderHandel = async () => {
    cancelOrder({
      body: {
        product_id: params?.cancelProductId,
        productStatus: "Cancelled",
      },
      orderId: params?.cancelOrderId,
      user: localStorage.getItem("user"),
    }).then((res) => {
      if (res?.data) {
        toast.success(`Order canceled successfully.`);
        navigate("/profile-details#order-history");
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  };

  return (
    <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
      <div className="lg:w-[20%] h-[100dvh] edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>

        <span className="text-xl mt-10">Need Assistance ?</span>
        <div className="flex flex-col items-start">
          <span className="underline mt-3 cursor-pointer select-none">
            Contact Us
          </span>
          <div className="flex items-center mt-3">
            <div className="material-symbols-outlined">
            <img src={CALL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2">18002028223 </span>
          </div>
          <div className="flex items-center">
            <div className="material-symbols-outlined ">
            <img src={MAIL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2"><a href='mailto:info@netre.in' className="email-link">help@netre.in</a></span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap hide_scrollbar">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>
      </div>

      <div className="lg:w-[80%] emd:w-full small:w-full p-6">
        <div className="w-full flex pb-4 border-b border-light_secondary">
          <div
            className="material-symbols-outlined cursor-pointer"
            onClick={() => navigate("/profile-details#order-history")}
          >
            <img src={ARROW_BACK_BLACK_IC} alt="" className="w-6" />
          </div>
          <span className="text-2xl font-bold ms-4">Cancel Order</span>
        </div>
        <div className="w-full my-5 flex justify-between flex-wrap">
          <div className="lg:w-full emd:w-full small:w-full flex flex-col lg:pr-2">
            <div className="w-full flex items-start justify-between pb-4 border-b">
              <div className="flex flex-col">
                <span className="text-base font-semibold">
                  Order Number : {customerOrderDetailsData?.invoice}
                </span>
                <span className="text-base font-semibold">
                  Order Date : {formattedOrderDate}
                </span>
                <span className="text-base font-semibold">
                  Estimated Delivery : {formattedEstimatedDeliveryDate}
                </span>
              </div>
              <BTN
                title="Cancel"
                className="w-fit py-1 px-4"
                onP={cancelOrderHandel}
              />
            </div>

            <div className="w-full mt-5 flex lg:flex-row emd:flex-col small:flex-col justify-between items-center">
              <div className="lg:w-fit emd:w-full small:w-full flex flex-col">
                <span className="text-lg">Deliver to</span>
                <span className="text-sm">
                  {customerOrderDetailsData?.user_info?.address?.address1 +
                    ", " +
                    customerOrderDetailsData?.user_info?.address?.zipCode +
                    ", " +
                    customerOrderDetailsData?.user_info?.address?.city}
                </span>
              </div>
              <div className="lg:w-fit emd:w-full small:w-full lg:mt-0 emd:mt-5 small:mt-5 flex flex-col">
                <span className="text-lg">Mode of payment</span>
                <span className="text-sm">
                  {customerOrderDetailsData?.paymentMethod}
                </span>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 emd:w-full small:w-full flex flex-col p-5 lg:mt-0 emd:mt-5 small:mt-"></div>
        </div>
      </div>
    </div>
  );
};

export default CancelOrder;
