import React, { useEffect, useState } from "react";
import Slider from "./Slider/Slider";
import { useGetProductsMutationMutation } from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";

export default function BestSellingFrame({ homeData, blueCutLensIdDataLabel }) {

  const [newData, setNewData] = useState();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [getProductsMutation] = useGetProductsMutationMutation();

  useEffect(() => {
    getProductsMutation({
      body: {},
      page: page,
      limit: 10,
      queryParams : `&price=best-selling`
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res?.data.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, []);

  return (
    <div className="w-full">
      <div className="w-full flex mt-10 items-center justify-between">
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
        <span className="lg:text-3xl emd:text-xl small:text-lg text-center text-nowrap">{homeData?.best_selling_section?.title}</span>
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
      </div>
      <Slider products={products} isRetailer={false} blueCutLensIdDataLabel={blueCutLensIdDataLabel} />
    </div>
  );
}