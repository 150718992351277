import React from "react";

const TermsCondition = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Netré Care</h1>
      <p className="text-lg mb-6">
        Protect Your Purchase from Accidental Damages
      </p>

      <section>
        <h2 className="text-2xl font-semibold mb-3">Terms & Conditions:</h2>
        <ol className="list-decimal list-inside mb-6">
          <li>
            Netré Care will cover frames and lenses for 12 months from the date
            of purchase.
          </li>
          <li>
            Customers can add Netré Care to their purchase during checkout or
            from order history in their profile before the products are
            delivered to them. Adding or removing Netré Care is not possible
            after the delivery has been fulfilled.
          </li>
          <li>
            Netré Care is separate from the return period on the order, although
            it overlaps, by default, the products will be eligible for exchanges
            and returns during the return period as per the T&C of the order.
          </li>
          <li>
            Customer will be charged Rupees 149 for the frame, and Rupees 199
            for a single vision lens (a one-time premium) to avail Netré Care
            and secure their purchase.
          </li>
          <li>
            Netré Care on Progressive lens purchases will only be allowed to be
            changed at a flat 50% discount as a one-time only offer, during the
            time of care policy, which is 1 year. Netré Care for progressive
            lenses will be charged at Rupees 99 to apply.
          </li>
          <li>
            Netré Care will cover any accidental damages like breakage, cracks,
            and chip-offs for both frames and lenses.
          </li>
          <li>
            The Customer has to provide the damaged frame/lens at the time of
            claiming Netré Care.
          </li>
          <li>Netré Care will not cover any lost products.</li>
          <li>
            Customers will be charged a Co-Pay of rupees 100 for the new frames
            and rupees 149 for a new lens.
          </li>
          <li>
            If any customer’s Rx (prescription) gets changed in the valid period
            we will provide a 50% discount on the new lens with the new power.
            i.e. if the customer has paid 2000 Rs for the lens the customer has
            to pay 1000 Rs for the new lens. (Provided the customer keeps the
            same frame. If the customer wants to change the frames, the full
            price on the frames will be charged along with 50% on the lenses.
          </li>
          <li>
            Netré Care redemption is not to be combined with any other offers or
            coupons.
          </li>
          <li>
            Netré Care is applicable only one time. The same will not apply to
            the claimed product.
          </li>
          <li>
            Netré Care is not applicable in scratches or any manufacturing
            damage like coating pill off, spots on lenses or plating issues on
            frames. A manufacturing warranty will be applicable for these types
            of cases.
          </li>
        </ol>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-3">Additional Benefits:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            If there is no damage within said period, the customer is eligible
            to avail of a new spectacle (with a new frame and lens) at a flat
            50% discount. The same will not apply to the exchanged product.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-3">Notes:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>Some brands or categories will not be covered in Netré Care.</li>
          <li>
            Customers can cancel Netré Care before the delivery of the products
            and will get a refund on the same payment mode within 7 to 10
            business days, or get instant credit as a coupon on their profile to
            redeem on future purchases.
          </li>
          <li>No cancellation is possible after the delivery of products.</li>
          <li>
            Only one product covered: If the customer is availing of multiple
            pair offers, only one product will be covered in Netré Care within
            the policy period.
          </li>
          <li>Refer to a Netré representative for more information.</li>
        </ul>
      </section>
    </div>
  );
};

export default TermsCondition;
