import "./lenseOptions.scss";

import React, { useState } from "react";

import ReactCompareImage from "react-compare-image";

function LenseOptions({ homeData }) {
  const tabs = [
    "Polorization",
    "Blue Light",
    "Tints",
    "Mirror",
    "Transitions",
    "UV/Clear",
  ];
  const before =
    "https://upload.wikimedia.org/wikipedia/commons/f/f5/Poster-sized_portrait_of_Barack_Obama.jpg";
  const after =
    "https://hindalkindi1992.files.wordpress.com/2013/11/portrait_eyes_23.jpg";

  const before2 = "/before.jpg";
  const after2 = "/after.jpg";

  const [beforeImg, setBeforeImg] = useState(before2);
  const [afterImg, setAfterImg] = useState(after2);

  const [tab, setTab] = useState(0);
  const handleTabSelect = (ind) => {
    setTab(ind);
    if (ind === 0) {
      setBeforeImg(before2);
      setAfterImg(after2);
    } else if (ind === 1) {
      setBeforeImg(before);
      setAfterImg(after);
    } else if (ind === 2) {
      setBeforeImg(before2);
      setAfterImg(after2);
    } else if (ind === 3) {
      setBeforeImg(before);
      setAfterImg(after);
    } else if (ind === 4) {
      setBeforeImg(before2);
      setAfterImg(after2);
    } else {
      setBeforeImg(before);
      setAfterImg(after);
    }
  };
  return (
    <div className="LenseOptions_Container my-5">
      <div className="lense-title-container">
        <div className="hr"></div>
        <div className="title text-nowrap">Lense Options</div>
        <div className="hr"></div>
      </div>
      <div className="contentWrapper my-2">
        <ReactCompareImage leftImage={beforeImg} rightImage={afterImg} />
      </div>
      <div className="Tab_Container ">
        {homeData?.lens_options?.option_tabs?.map((item, index) => (
          <div
            key={index}
            className={`${
              tab === index ? "tab active font-mont" : "tab font-mont"
            } text-nowrap`}
            onClick={() => handleTabSelect(index)}
          >
            {item?.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LenseOptions;
