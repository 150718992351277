import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LazyImageLoader = ({imgClassName, imgSrc, imgHeight, imgWidth, imgObjectFit, imgAlt}) => {
    const [isLoading, setIsLoading] = useState();

    const handleBeforeLoad = () => {
        setIsLoading(true);
    };

    const handleAfterLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            {isLoading && <Skeleton
                className={imgClassName}
                style={{height: imgHeight || '100%', width: imgWidth || '100%'}}
                count={1}
            />}
            {<LazyLoadImage
                style={{ display: isLoading ? 'none' : 'block', height: imgHeight || '100%', width: imgWidth || '100%', objectFit: imgObjectFit || 'contain' }}
                beforeLoad={handleBeforeLoad}
                onLoad={handleAfterLoad}
                alt={imgAlt || 'image'}
                src={imgSrc}
                className={imgClassName}
            />
            }
        </>
    );
};

export default LazyImageLoader;