import React, { useRef } from "react";
import { CLOSE_BLACK_IC } from "../../assets";

const MODEL = ({
  isDialogue,
  isWalletSection,
  showModel,
  children,
  setShowModel = () => {},
  title,
}) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModel(false);
    }
  };

  return (
    <>
      {showModel ? (
        <>
          <div
            className="fixed left-0 top-0 z-9999 flex h-full min-h-screen w-full items-center justify-center bg-black/50 px-4 py-5"
            onClick={handleClickOutside}
          >
            <div
              ref={modalRef}
              className={` ${
                isDialogue
                  ? "small:w-[95%] sm:w-[75%] md:w-[50%] lg:w-[25%]"
                  : isWalletSection
                  ? "small:w-[95%] md:w-[75%] lg:w-[40%]"
                  : "small:w-[95%] md:w-[95%] lg:w-[60%]"
              } border-0 overflow-hidden shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none`}
            >
              {title !== undefined && (
                <div className="flex items-center justify-between p-4 md:p-5 border-[#EEEEEE]">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {title}
                  </h3>
                  <button
                    onClick={() => setShowModel(!showModel)}
                    type="button"
                    className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                    data-modal-toggle="crud-modal"
                  >
                    <img src={CLOSE_BLACK_IC} alt="" />
                  </button>
                </div>
              )}
              <div className="flex items-center justify-between">
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-999 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default MODEL;