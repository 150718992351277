import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  items: [],
};

const homeTryOnSlice = createSlice({
  name: "homeTryOnData",
  initialState,
  reducers: {
    setHomeTryOn: (state, action) => {
      state.items = action.payload;
    },
    addToHomeTryOn: (state, action) => {
      const product = action.payload;
      const existingProduct = state.items.find(
        (item) => item._id === product._id
      );

      if (existingProduct) {
        existingProduct.quantity += product.quantity || 1;
        state.orderDetails = { ...state.orderDetails };
      } else {
        state.items.push({ ...product, quantity: product.quantity || 1 });
        state.orderDetails = { ...state.orderDetails };
      }
    },
    removeFromHomeTryOn: (state, action) => {
      const productId = action.payload?.id;
      const decrement = action.payload.isAllDelete;
      const existingProduct = state.items.find(
        (item) => item._id === productId
      );

      if (existingProduct) {
        existingProduct.quantity -= 1;
        if (existingProduct.quantity <= 0) {
          state.items = state.items.filter((item) => item._id !== productId);
          state.orderDetails = { ...state.orderDetails };
        }
        if (decrement) {
          state.items = state.items.filter((item) => item._id !== productId);
          state.orderDetails = { ...state.orderDetails };
        }
      }
    },
  },
});

export const { setHomeTryOn, addToHomeTryOn, removeFromHomeTryOn } =
  homeTryOnSlice.actions;
export default homeTryOnSlice.reducer;
