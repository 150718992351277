import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const myBagToggleSlice = createSlice({
  name: "myBagToggle",
  initialState,
  reducers: {
    myBagToggle: (state, action) => {
      return !state;
    },
  },
});

export const { myBagToggle } = myBagToggleSlice.actions;
export default myBagToggleSlice.reducer;
