import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CLOSE_BLACK_IC, CLOSE_IC, MENU_IC } from "../../assets";
import { Breakpoint } from "react-socks";

const RetailerHeader = ({
  eyeFramesRef,
  newArrivalsRef,
  contactUsRef,
  ourStoreRef,
}) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const handleEyeFrames = () => {
    eyeFramesRef.current.scrollIntoView({ behavior: "smooth" });
    setMenuToggle(false);
  };
  const handleNewArrival = () => {
    newArrivalsRef.current.scrollIntoView({ behavior: "smooth" });
    setMenuToggle(false);
  };
  const handleOurStore = () => {
    ourStoreRef.current.scrollIntoView({ behavior: "smooth" });
    setMenuToggle(false);
  };
  const handleContactUs = () => {
    contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    setMenuToggle(false);
  };


  return (
    <div className="p-f t-0 l-0  w-full bg-white py-5 px-4 flex justify-between items-center z-999">
      <Link to="/">
        <img className="netre-logo" src="/Netre-logo.svg" alt="Netre" />
      </Link>
      <div className="lg:flex emd:flex small:hidden items-center justify-center mt-1">
        <span
          onClick={() => handleEyeFrames()}
          className="cursor-pointer text-nowrap select-none me-7"
        >
          EYE FRAMES
        </span>
        <span
          onClick={() => handleNewArrival()}
          className="cursor-pointer text-nowrap select-none me-7"
        >
          NEW ARRIVALS
        </span>
        <span
          onClick={() => handleOurStore()}
          className="cursor-pointer text-nowrap select-none me-7"
        >
          OUR STORE
        </span>
        <span
          onClick={() => handleContactUs()}
          className="cursor-pointer text-nowrap select-none"
        >
          CONTACT US
        </span>
        {/* <span className="cursor-pointer text-nowrap select-none text-primary">
          OFFERS %
        </span> */}
      </div>
      <div
        onClick={() => setMenuToggle(true)}
        className="lg:hidden emd:hidden small:flex"
      >
        <img src={menuToggle ? CLOSE_IC : MENU_IC} alt="" className="w-6" />
      </div>
      {menuToggle ? (
        <div className="absolute left-0 bg-white p-5 top-16 w-full z-99999 flex flex-col items-center justify-center">
          <span
            onClick={() => handleEyeFrames()}
            className="cursor-pointer text-nowrap select-none border-b border-light_secondary w-full pb-4 text-center"
          >
            EYE FRAMES
          </span>
          <span
            onClick={() => handleNewArrival()}
            className="cursor-pointer text-nowrap select-none border-b border-light_secondary w-full pb-4 pt-4 text-center"
          >
            NEW ARRIVALS
          </span>
          <span
            onClick={() => handleOurStore()}
            className="cursor-pointer text-nowrap select-none border-b border-light_secondary w-full pb-4 pt-4 text-center"
          >
            OUR STORE
          </span>
          <span
            onClick={() => handleContactUs()}
            className="cursor-pointer text-nowrap select-none w-full pt-4 text-center "
          >
            CONTACT US
          </span>
        </div>
      ) : (
        <></>
      )}
      {menuToggle && (
        <div
          className={`fixed top-0 left-0 w-full h-full transition-all duration-75 z-999 ${
            menuToggle ? "opacity-50 " : ""
          }`}
          style={{
            background: "linear-gradient(to bottom, transparent, #0000004c)",
          }}
          onClick={() => {
            setMenuToggle(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default RetailerHeader;
