import "./browseFaceShape.scss";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Breakpoint } from "react-socks";

export default function BrowseFaceShape({ homeData }) {
  const navigate = useNavigate();

  //   {
  //     "attributeName": "Shape",
  //     "attributeValues": [
  //         "Round",
  //         "Cat Eye",
  //         "Wayfarer",
  //         "Rectangle",
  //         "Square",
  //         "Hexagon",
  //         "Oval"
  //     ]
  // },

  return (
    <div className="BrowseWiseFaceShape_Container lg:pb-[50px] emd:pb-[30px] small:pb-[20px] ">
      <div className="BrowseWiseFaceShape_Title font-sabon">
        <h1 className="font-sabon">{homeData?.shapes_section?.title}</h1>
      </div>
      <Breakpoint large up>
        <div className="BrowseWiseFaceShape_Block">
          {homeData?.shapes_section?.banners?.map((item, index) => (
            <div className="BrowseWiseFaceShape_card" key={index}>
              <div className="Card_img">
                <img
                  src={item.background}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="Overlay"></div>
              <div className="Card_img_Overlay">
                <img
                  src={item.image}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div
                onClick={() =>
                  navigate(item?.redirect, {
                    state: { shape: item?.button_text },
                  })
                }
                className="Card_Title"
              >
                <span>{item.button_text}</span>
              </div>
            </div>
          ))}
        </div>
      </Breakpoint>

      <Breakpoint medium down>
        <div className="carousel">
          {homeData?.shapes_section?.banners?.map((item, index) => (
            <div className="BrowseWiseFaceShape_card" key={index}>
              <div className="Card_img">
                <img
                  src={item.background}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="Overlay"></div>
              <div className="Card_img_Overlay">
                <img
                  src={item.image}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div
                onClick={() => navigate(item?.redirect)}
                className="Card_Title"
              >
                <span>{item.button_text}</span>
              </div>
            </div>
          ))}
        </div>
      </Breakpoint>

      <div
        onClick={() => navigate(homeData?.shapes_section?.redirect)}
        className="See_All_Frame_btn py-2 px-3 lg:text-base emd:text-base small:text-sm lg:mt-[50px] emd:mt-[30px] small:mt-[20px] text-nowrap"
      >
        {homeData?.shapes_section?.button_text}
      </div>
    </div>
  );
}
