import React from "react";
import { CONTACT_US_BG } from "../../../assets";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import { useContactUsMutation } from "../../../redux/slices/homeSlice";
import { useFormik } from "formik";
import { isValidEmail } from "../../Footer/Footer";
import { toast } from "react-toastify";

const BecomeRetailer = () => {
  const [contactUs] = useContactUsMutation();

  const contactFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      isRetailer: true,
    },
    onSubmit: async function (values) {
      contactUs({
        body: {
          name: values?.name,
          email: values?.email,
          phone: values?.phone,
          message: values?.message,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          contactFormik?.resetForm();
          toast.success("Message sent successfully.");
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.phone) {
        errors.phone = "Phone number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      } else if (!values?.email) {
        errors.email = "email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Invalid Email!";
      } else if (!values?.name) {
        errors.name = "Name is Required!";
      } else if (!values?.message) {
        errors.message = "Message is Required!";
      }
      return errors;
    },
  });

  return (
    <div className="w-full h-full bg-[#FCF5CC] flex items-center justify-center">
      <div className="h-full py-5 lg:w-[50%] emd:w-full small:w-full lg:px-0 emd:px-4 small:px-3 flex flex-col items-center justify-center">
        <span className="text-2xl">Become an authorized retailer</span>
        <INPUT
          mainClass={"lg:w-1/2 emd:w-full small:w-full"}
          placeHolder="Phone No."
          className="mt-5"
          name="phone"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.phone}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.phone}
          touched={contactFormik.touched.phone}
        />
        <INPUT
          placeHolder="Email"
          mainClass={"lg:w-1/2 emd:w-full small:w-full"}
          className="mt-5"
          name="email"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.email}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.email}
          touched={contactFormik.touched.email}
        />
        <INPUT
          placeHolder="Store name"
          mainClass={"lg:w-1/2 emd:w-full small:w-full"}
          className="mt-8"
          name="name"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.name}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.name}
          touched={contactFormik.touched.name}
        />
        <INPUT
          placeHolder="Message"
          mainClass={"lg:w-1/2 emd:w-full small:w-full"}
          rows={3}
          className="mt-5 bg-[#FCF5CC]"
          name="message"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.message}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.message}
          touched={contactFormik.touched.message}
        />
        <BTN
          className={"mt-10 m-auto small:w-full md:w-1/2 lg:w-1/2"}
          title="Submit"
          type="submit"
          onP={contactFormik?.handleSubmit}
        />
      </div>
      <div className="lg:w-[50%] lg:flex emd:hidden small:hidden h-full">
        <img src={CONTACT_US_BG} alt="" className="w-full object-cover" />
      </div>
    </div>
  );
};

export default BecomeRetailer;
