import React, { useState } from "react";
import { DOWN_ARROW_IC } from "../../../assets";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className={` ${isActive && "active_title"} accordion-title`}
        onClick={() => setIsActive(!isActive)}
      >
        <div>{title}</div>
        <img
          src={DOWN_ARROW_IC}
          className={`${isActive ? "ac_tr_arrow_ic" : ""} ac_arrow_ic`}
          alt=""
        />
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
