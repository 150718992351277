import React from "react";

const ExchangePolicy = () => {
  return (
    <div className="container mx-auto p-6">
      {/* Return, Refund, or Exchange Policy */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">
          Return, Refund, or Exchange Policy
        </h2>
        <p className="mb-4">
          We provide you with an opportunity to return, refund, or exchange your
          purchase from our website under the following conditions:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>
            Request for return, exchange, or refund must be made within 15 days
            of receiving the products.
          </li>
          <li>
            Products must be returned with intact price tags, identification
            tags, invoices, and original packaging. Any packaging items not
            returned shall be charged and be deducted from your refund amount.
            The refund amount will be cleared after we receive the products.
          </li>
          <li>
            Products must be unused, without any dirt, scratches, tears, or any
            damage rendering them unsellable.
          </li>
          <li>
            Provide the order number, date of order, and date of receipt with
            your request.
          </li>
          <li>
            Ensure the return package is properly sealed to avoid damage during
            transit.
          </li>
          <li>
            Our dedicated team will assess returned products, and their decision
            will be final.
          </li>
          <li>
            If your request is accepted, any shipping charges paid will be
            non-refundable. Upon request, a customer care agent can review your
            case and award a value voucher redeemable towards your next
            purchase.
          </li>
          <li>
            Contact our customer care to initiate the return, refund, or
            exchange process.
          </li>
        </ul>
        <p className="mt-4">Additional requirements for specific products:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>
            For product boxes: Ensure all components are intact, with no stains
            or damage.
          </li>
          <li>
            For lenses: Ensure no scratches, return with original box and
            accessories.
          </li>
          <li>
            For glasses: Ensure frames are not bent or twisted or damaged.
            Return with original box and accessories.
          </li>
        </ul>
        <p className="mt-4">
          Refund, return, or exchange will be initiated upon receiving the
          product in the specified condition. You can choose a refund, voucher,
          or exchange for a product of equal value. Refunds are processed within
          30 working days.
        </p>
        <p className="mb-4">
          For cash-on-delivery orders, refunds are not issued. Customers may
          exchange purchased items for another product, with no refund for price
          differences. Customers could request a redeemable value voucher for
          the cash purchase. Refunds will be returned on the same mode of
          payment used during purchase.
        </p>
        <p>This policy applies only to customers in India.</p>
      </section>
    </div>
  );
};

export default ExchangePolicy;
