import './netreFrames.scss'

import { Power2, Power4, ScrollTrigger, gsap } from "gsap/all";
import React,{useLayoutEffect} from 'react'

gsap.registerPlugin(ScrollTrigger)
export default function NetreFrame(props) {
    useLayoutEffect(()=>{
        let action=gsap.timeline({
          pause:true

        })
        .to('.Netre_tile',{borderRadius:"0",width:"25vw", margin:0,duration:0.05},0.00)
        .to('.overlay',{opacity:1,duration:0.05},0.144)
        .to('.Content_Container',{opacity:1,duration:0.05},0.144)
        .to('.Bottom_Circle',{opacity:1,duration:0.05},0.144)
        .to('.Circle_Container',{opacity:1, top:0,duration:0.05},0.144)
        .to('.Netre_Tiles_Block',{top:"-100vh",duration:0.05},0.320)
        .to('.Netre_Frame_Shapes_Block',{top:"-100vh",duration:0.05},0.320)
          .to('.Bottom_Circle',{top:"150%",duration:0.05},0.320)
        .to('.Tri-1',{top:"30%",left:'46%',duration:0.05},0.450)
        .to('.Rec-1',{top:"40%",left:'46%',duration:0.05},0.450)
        .to('.Sq-1',{top:"50%",left:'46%',duration:0.05},0.450)
        .to('.Ov-1',{top:"50%",left:'46%',duration:0.05},0.450)
        .to('.Cr-1',{top:"50%",left:'46%',duration:0.05},0.450)
       
        ScrollTrigger.create({
            trigger: ".Netre_Frames_Container",
            pin: '.Netre_Frames_Container', 
            pinSpacing:true,  
            scrub:0.1,
            animation:action,
            start: "top top",
            end: "+=1500",
            markers:true,
            onUpdate: (self) => {
              //  info for position
            
            }
         
        })
      
    },[])

    return (
        <div className='Netre_Frames_Container'>
        
            <div className='Netre_Tiles_Block'>
            <div className='Content_Container'>
                <div className='Content_title'>Netre Makes You Good</div>
                <div className='Content_Para'>Unlock The Potential of Your Eye</div>
                
            </div>
            <div className='overlay'></div>
            <div className='Bottom_Circle'></div>
            <div className='Circle_Container'>
            
                    <div className='Circle-1'>
                        
                    </div>
                    <div className='Circle-2'>
                        
                        </div>
                </div>
                <div className='Netre_Tiles_Container'>
                    <div className='Netre_tile tile-1' style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-2'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-3'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-4'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-5'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-6'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-7'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                    <div className='Netre_tile tile-8'  style={{backgroundImage:`url(/eyeglasses-brands.webp)`}}></div>
                </div>
            </div>

            <div className='Netre_Frame_Shapes_Block'>
                <div className='Netre_Shapes_Container'>
                    <div className=' triangle Tri-1'>&#9650;</div>
                    <div className='shape rectangle Rec-1'></div>
                    <div className='shape Square Sq-1'></div>
                    <div className='shape Oval Ov-1'></div>
                    <div className='shape Circle Cr-1'></div>


                    <div className=' triangle Tri-2'>&#9650;</div>
                    <div className='shape rectangle Rec-2'></div>
                    <div className='shape Square Sq-2'></div>
                    <div className='shape Oval Ov-2'></div>
                    <div className='shape Circle Cr-2'></div>


                    <div className=' triangle Tri-3'>&#9650;</div>
                    <div className='shape rectangle Rec-3'></div>
                    <div className='shape Square Sq-3'></div>

                    <div className=' triangle Tri-4'>&#9650;</div>
                    <div className='shape rectangle Rec-4'></div>
                    <div className='shape Square Sq-4'></div>

                    <div className=' triangle Tri-5'>&#9650;</div>

                    <div className=' triangle Tri-6'>&#9650;</div>
                </div>
            </div>
        </div>
    )
}
