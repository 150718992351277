import "./breadcrumbs.scss";

import { Link } from "react-router-dom";
import React from "react";

export default function Breadcrumbs({ breadcrumbs, className }) {
  return (
    <div className={`breadcrumbs ${className}`}>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          {index > 0 && <span className="separator">/</span>}
          {breadcrumb.link ? (
            <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
          ) : (
            <span>{breadcrumb.label}</span>
          )}
        </span>
      ))}
    </div>
  );
}
