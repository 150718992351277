import { useGetUserProfileQuery } from "../redux/slices/homeSlice";

function useGetUser() {
  let user = localStorage.getItem("user");
  const { data, isLoading, isSuccess, isError, error } = useGetUserProfileQuery(
    { user: user },
    { skip: !user }
  );
  // console.log(data, isLoading, isSuccess, isError, error)
  if (isSuccess) {
    return data;
  }
  if (isError) {
    return error;
  }
}
export default useGetUser;
