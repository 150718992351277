import React from 'react'

const Accessories = () => {
  return (
    <div className='w-full h-[100dvh]' >
      
    </div>
  )
}

export default Accessories
