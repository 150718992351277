import React, { useEffect, useState } from "react";
import BTN from "../../Master/BTN";
import LtoRScrollListing from "../../Master/LtoRScrollListing";
import MODEL from "../../Master/MODEL";
import {
  useGetOrderDetailsDataQuery,
  useGetProductsMutationMutation,
  useScheduleDeliveryMutation,
} from "../../../redux/slices/homeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ORDER_SUCCESS from "../../../assets/svg/orderSuccess.gif";
import Lottie from "react-lottie";
import netreHomeCust from "../../../json/netreHomeCust.json";
import { DEPLOY_IC, PACE_IC, PAYMENT_FAIL_ICON } from "../../../assets";
import { PageNotFound } from "../../../App";

const OrderFailed = ({blueCutLensIdDataLabel}) => {
  const user = localStorage.getItem("user");
  const params = useParams();
  const navigate = useNavigate();

  // const timeData = ["After 5 PM", "9 AM to 6 PM", "7 AM to 10 PM"];

  // const [scheduleToggle, setScheduleToggle] = useState(false);
  // const [selectedDate, setSelectedDate] = useState("");
  // const [selectedTime, setSelectedTime] = useState("");

  const {
    data: customerOrderDetailsData,
    isSuccess: customerOrderDetailsDataIsSuccess,
    isLoading: customerOrderDetailsDataIsLoading,
    isError: customerOrderDetailsDataIsError,
    error: customerOrderDetailsDataError,
  } = useGetOrderDetailsDataQuery({
    user: user,
    orderId: params?.orderId,
  });
  const [navigateToNotFound, setNavigateToNotFound] = useState(false);
  useEffect(() => {
    if (customerOrderDetailsDataIsSuccess && customerOrderDetailsData?.cart) {
      console.log("customerOrderDetailsData", customerOrderDetailsData?.cart);
      customerOrderDetailsData.cart.map((item) => {
        console.log("item", item.orderStatus);
        if(item.orderStatus != "Not Placed"){
          setNavigateToNotFound(true);
        }
      });
    }
  },[customerOrderDetailsDataIsSuccess]);

  // function generateNextSevenDays(startDateString) {
  //   const startDate = new Date(startDateString);
  //   const datesArray = [];

  //   for (let i = 0; i < 7; i++) {
  //     const nextDate = new Date(startDate);
  //     nextDate?.setDate(startDate?.getDate() + i + 7);
  //     const formattedDate = nextDate?.toISOString()?.split("T")[0];
  //     datesArray?.push(formattedDate);
  //   }
  //   return datesArray;
  // }

  // const dates = customerOrderDetailsDataIsSuccess
  //   ? generateNextSevenDays(customerOrderDetailsData?.createdAt)
  //   : [];

  // function formatDateString(dateString) {
  //   const date = new Date(dateString);

  //   // Array of day abbreviations
  //   const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  //   // Get day of the week and day of the month
  //   const dayOfWeek = daysOfWeek[date?.getUTCDay()];
  //   const dayOfMonth = date?.getUTCDate();

  //   // Format the result as "Day Abbreviation D"
  //   return { day: dayOfWeek, week: dayOfMonth };
  // }

  // // ----------------------- HANDLE SCHEDULE DATE AND TIME --------------------

  // const [scheduleDelivery] = useScheduleDeliveryMutation();

  // const scheduleDeliveryHandle = () => {
  //   if (selectedDate === "") {
  //     toast.error("Select Delivery Date!");
  //     return;
  //   }
  //   if (selectedTime === "") {
  //     toast.error("Select Delivery Time!");
  //     return;
  //   }

  //   const bodyObj = {
  //     orderId: params?.orderId,
  //     deliveryDate: selectedDate,
  //     deliveryTime: selectedTime,
  //   };

  //   scheduleDelivery({
  //     body: bodyObj,
  //     user: localStorage.getItem("user"),
  //   }).then((res) => {
  //     if (res?.data) {
  //       setSelectedDate("");
  //       setSelectedTime("");
  //       setScheduleToggle(false);
  //       toast.success(`Order's delivery scheduled successfully.`);
  //     } else {
  //       toast.error(`${res?.error?.data?.message}`);
  //     }
  //   });
  // };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: netreHomeCust,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  const [newData, setNewData] = useState();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [getProductsMutation] = useGetProductsMutationMutation();

  useEffect(() => {
    getProductsMutation({
      body: {},
      page: page,
      limit: 10,
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res?.data.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, []);
  
  // error handling
  if (customerOrderDetailsDataIsError || navigateToNotFound) {
    return <PageNotFound/>  
  }
  return (
    <>
    {customerOrderDetailsDataIsLoading && <h2
                style={{
                  margin: "100px 50px",
                }}
    >Loading...</h2> }
    {customerOrderDetailsDataIsSuccess &&
    <div className="w-full items-center flex flex-col px-6 pb-6 pt-5">
      <img src={PAYMENT_FAIL_ICON} alt="" className="w-16" />
      {/* { netreHomeCust && <Lottie options={defaultOptions} height={400} width={400} />} */}
      <div className="w-full mt-10 flex flex-col items-center text-center ">
        <span className="text-3xl font-bold">Oops !</span>
        <span className="text-xl w-3/4 mt-3 mb-3 font-bold">
        We regret to inform you that there was an issue processing your recent order. Unfortunately, your order could not be completed at this time.
        </span>
        <span className="text-xl w-3/4 mt-3 mb-3 font-bold">
        If you need assistance or have questions, don’t hesitate to reach out to our customer support team.
        </span>
      </div>
      {/* <div className="w-full flex flex-col items-start">
        <span className="text-3xl font-bold">What You Can Do:
        </span>
        <ul>
          <li><span className="text-xl w-3/4 mt-3 mb-3 font-bold">Retry: Please try placing your order again.</span></li>
          <li><span className="text-xl w-3/4 mt-3 mb-3 font-bold">Retry: Please try placing your order again.</span></li>
          <li><span className="text-xl w-3/4 mt-3 mb-3 font-bold">Retry: Please try placing your order again.</span></li>
        </ul>
      </div> */}
      {/* <span className="text-2xl mt-3">You have saved ₹2400 </span> */}
      {/* <div className="w-full flex mt-5 justify-center items-center">
        <div
          onClick={() => navigate("/profile-details#order-history")}
          className="lg:w-1/4 emd:w-1/3 small:w-[48%] mt-3 py-6 px-4 max-h-[160px] min-h-[160px] cursor-pointer bg-gray border border-light_secondary flex flex-col items-center justify-center"
        >
          <div className="material-symbols-outlined text-center">
            <img src={DEPLOY_IC} alt="" className="" />
          </div>
          <span className="mt-4 text-center text-xl">My orders</span>
        </div>
        <div
          onClick={() => setScheduleToggle(true)}
          className="lg:w-1/4 emd:w-1/3 small:w-[48%] mt-3 py-6 px-4 max-h-[160px] cursor-pointer min-h-[160px] ms-2 bg-gray border border-light_secondary flex flex-col items-center justify-center"
        >
          <div className="material-symbols-outlined text-center">
            <img src={PACE_IC} alt="" />
          </div>
          <span className="mt-4 text-center text-xl">Pick Date add Time</span>
        </div>
      </div> */}
      {/* <div className="lg:w-1/2 emd:w-1/2 small:w-full flex lg:flex-row small:flex-col mt-5 justify-between lg:items-center small:items-start  lg:text-xl small:text-lg">
        <div>Order Id : <span className="color-2">{customerOrderDetailsData?._id}</span></div>
        <div>Amount: &#8377;&nbsp;<span className="color-2">{customerOrderDetailsData?.total}</span></div>
      </div> */}
      {/* <div className="lg:w-1/2 md:w-10/12 emd:w-1/2 small:w-full lg:flex-row small:flex-col emd:flex-col mt-4 flex items-center justify-evenly">
        <div className="flex items-center">
          <input type="checkbox" className="accent-white" />
          <label htmlFor="sms" className="ms-2 text-base text-nowrap">
            Get delivery updates on SMS
          </label>
        </div>
        <div className="flex items-center accent-black">
          <input type="checkbox" />
          <label htmlFor="sms" className="ms-2 text-base text-nowrap">
            Get delivery updates on SMS
          </label>
        </div>
      </div> */}
      <div className="d-flex gap-6">
        <BTN
          title="Contact Us"
          bordered
          className="mt-10 py-2 px-9  small:w-full text-base"
          to={"/contact-us"}
        />
        <BTN
          title="Manage Orders"
          bordered
          className="mt-10 py-2 px-4  small:w-full text-base"
          to={"/profile-details#order-history"}
        />
      </div>
      <div className="w-full mt-16">
        <LtoRScrollListing title="People have also bought" data={products} blueCutLensIdDataLabel={blueCutLensIdDataLabel} />
      </div>
{/* 
      <MODEL
        title={"Pick date and Time"}
        showModel={scheduleToggle}
        setShowModel={setScheduleToggle}
      >
        <div className="w-full flex flex-col items-center justify-center p-5">
          <div className="w-full flex flex-col items-center">
            <div className="w-full grid grid-cols-7 gap-2 mb-10">
              {dates?.map((item, index) => {
                return (
                  <div
                    onClick={() => setSelectedDate(item)}
                    className={` ${
                      selectedDate === item
                        ? "border-primary border-2"
                        : "border-black"
                    } cursor-pointer flex flex-col items-center justify-center border h-24`}
                  >
                    <span>{formatDateString(item)?.day}</span>
                    <span>{formatDateString(item)?.week}</span>
                  </div>
                );
              })}
            </div>
            <div className="w-full grid grid-cols-4 gap-2 mb-12">
              {timeData?.map((item, index) => {
                return (
                  <div
                    onClick={() => setSelectedTime(item)}
                    key={index}
                    className={` ${
                      selectedTime === item
                        ? "border-primary border-2"
                        : "border-black"
                    } py-2 cursor-pointer flex flex-col items-center justify-center border`}
                  >
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <BTN
              title="Confirm"
              className="capitalize w-fit py-2 px-10"
              bordered
              onP={scheduleDeliveryHandle}
            />
          </div>
        </div>
      </MODEL> */}
    </div>}
    </>
  );
};

export default OrderFailed;
