import React from "react";
import { HOT_DESKTOP, HOT_MOBILE } from "../../../assets";
import { useNavigate } from "react-router-dom";
const HTO = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex items-center justify-center">
      <img
        onClick={() => navigate("/search")}
        src={HOT_DESKTOP}
        alt=""
        className="w-full lg:flex emd:hidden small:hidden"
      />
      <img
        onClick={() => navigate("/search")}
        src={HOT_MOBILE}
        alt=""
        className="w-full small:flex emd:flex lg:hidden"
      />
    </div>
  );
};

export default HTO;
