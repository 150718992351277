import { Link } from "react-router-dom";
import "./whatWeOffer.scss";

import React from "react";

function WhatWeOffer({ homeData }) {
  return (
    <div className="What_We_Offer_Container">
      <div className="What_We_Offer_Title_Container">
        <div className="What_We_Offer_title font-sabon">
          {homeData?.feature_banners?.title}
        </div>
        <div className="What_We_Offer_Para">
          {homeData?.feature_banners?.subtitle}
        </div>
      </div>
      <div className="What_We_Offer_Gallery">
        {homeData?.feature_banners?.banners?.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="Overlay"></div>
              <img
                alt=""
                src={item?.image}
                width="100%"
                height="100%"
                style={{ height: "100%" }}
              />
              <Link to={item?.redirect} className="try-now-btn mont-regular text-nowrap">
                {item?.button_text}
                <div className="overlay"></div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default WhatWeOffer;
