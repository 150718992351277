import { useLocation, useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import { toast } from "react-toastify";
import { useGetProductsMutationMutation } from "../../../redux/slices/homeSlice";

export default function Flexibleframes({ homeData, blueCutLensIdDataLabel }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [getProductsMutation] = useGetProductsMutationMutation();
  useEffect(() => {
    getProductsMutation({
      body: {},
      page: 1,
      limit: 10,
      queryParams: ``,
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res?.data.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, []);

  return (
    <div className="w-full bg-[#f2e4c9] py-10">
      <div className="w-full flex items-center justify-between">
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
        <span className="lg:text-3xl emd:text-xl small:text-lg text-center text-nowrap">
          New Arrivals
        </span>
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
      </div>
      <Slider
        products={products}
        isRetailer={false}
        blueCutLensIdDataLabel={blueCutLensIdDataLabel}
      />
    </div>
  );
}

{
  /* <div
className={` ${
  location.pathname.includes("retailer") ? "" : ""
} Flexible_Frames_Container`}
style={{
  backgroundImage: `url(${homeData?.promotion_banner?.background})`,
}}
>
<div className="Section_title_container">
  <div className="section-title font-fc-mont">
    {homeData?.promotion_banner?.title}
  </div>
  <div className="Section_Sub_title font-fc-sabon text-center">
    {homeData?.promotion_banner?.subtitle}
  </div>
</div>
<div
  onClick={() => navigate(homeData?.promotion_banner?.redirect)}
  className="try-now-btn mont-regular text-nowrap"
>
  {homeData?.promotion_banner?.button_text}
  <div className="overlay"></div>
</div>
</div> */
}
