import React from "react";
import Slider from "../home/BestSellingFrame/Slider/Slider";

const LtoRScrollListing = ({ title, data, classForTitle, blueCutLensIdDataLabel }) => {
  return (
    <div className="w-full">
      <div className="w-full flex mt-10 items-center justify-between">
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
        <span
          className={`lg:text-3xl emd:text-xl small:text-lg text-center text-nowrap ${classForTitle}`}
        >
          {title}
        </span>
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
      </div>
      {data?.length > 1 ? (
        <Slider products={data} isRetailer={false} blueCutLensIdDataLabel={blueCutLensIdDataLabel} />
      ) : (
        <div className="w-full py-10 flex items-center justify-center">
          <span className="text-2xl text-light_secondary">No Products</span>
        </div>
      )}
    </div>
  );
};

export default LtoRScrollListing;