import React from "react";
import "./Home.scss";
import { Breakpoint } from "react-socks";
import LazyImageLoader from "../Master/LazyImageLoader";
function MainBanner({ homeData }) {
  return (
    <>
      {/* {props.data.main_banner && <img
            className="Home-banner"
            src={props.data.main_banner}
            alt="Main Banner"
            title="Main Banner"
            style={{width: "100vw", maxWidth: "100%"}}
        />} */}
      <Breakpoint large up style={{ objectFit:"cover", height:"100dvh"}} >
        {homeData?.main_banner_images?.map((item, index) => {
          return (
            <LazyImageLoader
              key={index}
              imgClassName="Home-banner"
              imgSrc={item?.desktop}
              imgAlt="Main Banner"
              imgHeight="100dvh"
              imgWidth="100vw"
              imgObjectFit="cover"
            />
          );
        })}
      </Breakpoint>
      <Breakpoint medium down style={{ objectFit:"cover", height:"100dvh"}}>
        {homeData?.main_banner_images?.map((item, index) => {
          return (
            <LazyImageLoader
              key={index}
              imgClassName="Home-banner"
              imgSrc={item?.mobile}
              imgAlt="Main Banner"
              imgHeight="100dvh"
              imgWidth="100vw"
              imgObjectFit="cover"
            />
          );
        })}
      </Breakpoint>
    </>
  );
}
export default MainBanner;
