import { Fragment, MouseEvent } from "react";
import { Link } from "react-router-dom";
import LOADER from "./LOADER";

function BTN({
  onP,
  type,
  loading,
  title,
  disabled,
  className,
  bordered,
  lIc,
  rIc,
  showBoth,
  to,
  target,
}) {
  function Content() {
    return (
      <Fragment>
        <LOADER isLoading={loading} className="absolute-ctr" size={5} />
        <span className={`${rIc ? (showBoth ? "" : "opacity-0") : ""}`}>
          <img draggable={false} src={lIc} alt="" />
        </span>
        <span
          className={loading ? "opacity-0" : bordered ? "" : " text-white"}
          draggable={false}
        >
          {title}
        </span>
        <span className={`${lIc ? (showBoth ? "" : "opacity-0") : ""}`}>
          <img draggable={false} src={rIc} alt="" />
        </span>
      </Fragment>
    );
  }

  return to ? (
    disabled ? (
      <span
        className={`${className} BTN ${bordered ? "" : "normalBTN"} ${
          disabled ? "opacity-5" : ""
        } cursor-not-allowed text-nowrap`}
        draggable={false}
      >
        {Content()}
      </span>
    ) : (
      <Link
        to={to || ""}
        className={`${className} BTN ${bordered ? "" : "normalBTN"} ${
          disabled ? "opacity-5" : ""
        } text-nowrap`}
        target={target}
        draggable={false}
        disabled={disabled}
      >
        {Content()}
      </Link>
    )
  ) : (
    <button
      onClick={onP}
      type={type}
      disabled={disabled || loading}
      draggable={false}
      className={`${className} BTN ${bordered ? "" : "normalBTN"} + ${
        disabled || loading ? "cursor-not-allowed opacity-5" : ""
      } text-nowrap`}
    >
      {Content()}
    </button>
  );
}

export default BTN;
