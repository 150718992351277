import "./index.scss";
import "./index.css";

import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import App from "./App";
import { BreakpointProvider } from "react-socks";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import { homeSlice } from "./redux/slices/homeSlice";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <BreakpointProvider>
        <App />
      </BreakpointProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
