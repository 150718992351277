import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useGetUser from "../../../hooks/useGetUser";
import { useAddToCustomerBagMutation, useAddToWishlistMutation, useRemoveFromWishlistMutation } from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { addToMyBag } from "../../../redux/slices/myBagSlice";
import MODEL from "../../Master/MODEL";
import { CLOSE_BLACK_IC } from "../../../assets";


export default function SliderChild({ data, blueCutLensIdDataLabel }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (data?._id) {
        const found = wishlistData.find((element) => element._id === data._id);
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, data]);

  const user = useGetUser();
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!data._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: data._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: data._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };

  const redirctUrl = `/products/${data?._id}`;
  var image = null;

  image = data?.image[0] ? data?.image[0] : null;
  if (image) {
    var transformedImage = image.replace(
      "upload/",
      "upload/if_w_gt_400/w_400/if_end/"
    );
  }

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(data));
      toast.success(`${data?.name} added to bag successfully.`);
    }
  };

  // =================== ADD CART HANDLE =================
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: data?._id },
      });
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  // ====================== ADD ZERO POWER LENS HANDLE ================================

  const addZeroPowerLensHandle = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: 1,
              variant: [],
              zeroPower: true,
              lens: [
                {
                  lens_id: blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                  addons: [],
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: {
          forZeroPowerLensId: data?._id,
          isOnlyLens: false,
        },
      });
    }
    setLensAddModelToggle(false);
  };

  return (
    <div>
      <div className="child cursor-pointer lg:min-h-[28vw] emd:min-h-[35vw] small:min-h-[75vw] lg:max-h-[28vw] emd:max-h-[35vw] small:max-h-[75vw] lg:min-w-[28vw] emd:min-w-[35vw] small:min-w-[70vw] lg:max-w-[28vw] emd:max-w-[35vw] small:max-w-[70vw] ">
        <div className="w-full flex items-end justify-end">
          {wishListed ? (
            <svg
              onClick={handleWishlist}
              className="pointer filled"
              viewBox="0 -960 960 960"
              fill="#AC772B"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
                transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
              />
            </svg>
          ) : (
            <svg
              onClick={handleWishlist}
              className="pointer"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z" />
            </svg>
          )}
        </div>
        <img
          src={transformedImage ? transformedImage : "/Netre-logo.svg"}
          height="100%"
          width="100%"
          alt=""
          onClick={() => {
            navigate(redirctUrl);
          }}
        />
        <div
          onClick={() => {
            navigate(redirctUrl);
          }}
          className="Product_name emd:text-sm small:text-sm lg:text-lg mont-regular w-full text-ellipsis overflow-hidden"
        >
          {data?.name}
        </div>
        <div
          onClick={() => {
            navigate(redirctUrl);
          }}
          className="my-3 emd:text-sm small:text-sm lg:text-base"
        >
          ₹ {data?.prices?.price}
        </div>
        <span
          className="text-primary emd:text-sm small:text-sm lg:text-base"
          onClick={() => setLensAddModelToggle(true)}
        >
          Add to bag
        </span>
      </div>
      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setLensAddModelToggle(false);
              }}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <span
              onClick={() => {
                addCartHandle(true);
              }}
              className={
                "w-full mont-regular cursor-pointer text-nowrap lg:text-base emd:text-sm small:text-sm py-1 px-3 bg-secondary text-white text-center"
              }
            >
              Add Lens With Power
            </span>
            <span
              onClick={() => {
                addZeroPowerLensHandle();
              }}
              className={
                "w-full cursor-pointer text-nowrap mt-5 mont-regular lg:text-base emd:text-sm small:text-sm py-1 px-3 bg-white text-secondary border-secondary border text-center"
              }
            >
              Add Lens With Zero Power
            </span>

            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              className="text-primary text-nowrap cursor-pointer text-center lg:text-base emd:text-sm small:text-sm"
              onClick={() => addCartHandle(false)}
            >
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>
    </div>
  );
}
