// const INPUT = ({
//   val = "",
//   name,
//   title,
//   textColor,
//   placeHolder,
//   mainClass,
//   InputclassName,
//   rComp,
//   lComp,
//   readOnly,
//   className,
//   onChange = () => {},
//   rows,
//   type,
//   isDisabled,
//   onKeyDown,
//   phoneNum,
//   onBlur,
// }) => {
//   return (
//     <div className={`flex flex-col w-full ${mainClass}`}>
//       {title && (
//         <div className="flex mb-3 font-[400] text-sm items-center text-center">
//           <span className={`text-[#4B4B4B] font-[500] ${textColor}`}>
//             {title}
//           </span>
//         </div>
//       )}
//       {rows ? (
//         <>
//           <textarea
//             onBlur={onBlur}
//             rows={rows}
//             className={`${className} INPUT `}
//             name={name}
//             value={val}
//             placeholder={
//               placeHolder === ("" || undefined) ? `Enter ${title}` : placeHolder
//             }
//             onChange={(e) => onChange(e.target.value)}
//             onKeyDown={onKeyDown}
//           />
//         </>
//       ) : (
//         <>
//           <div className={`${className} INPUT`}>
//             {lComp}
//             <input
//               onBlur={onBlur}
//               readOnly={readOnly}
//               maxLength={phoneNum}
//               name={name}
//               disabled={isDisabled}
//               type={type}
//               value={val}
//               className={`${InputclassName} w-full bg-transparent outline-none `}
//               placeholder={
//                 placeHolder === ("" || undefined)
//                   ? `Enter ${title}`
//                   : placeHolder
//               }
//               onChange={(e) => {
//                 if (type === "tel") {
//                   const regex = /^[+]?[0-9]*$/;
//                   if (e.target.value === "" || regex.test(e.target.value)) {
//                     onChange(e.target.value);
//                   }
//                 } else {
//                   onChange(e?.target?.value);
//                 }
//               }}
//               onKeyDown={onKeyDown}
//             />
//             {rComp}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default INPUT;

const INPUT = ({
  val = "",
  name,
  title,
  textColor,
  placeHolder,
  mainClass,
  InputclassName,
  rComp,
  lComp,
  readOnly,
  className,
  onChange = () => {},
  rows,
  type,
  isDisabled,
  onKeyDown,
  maxLength,
  onBlur,
  touched,
  errorMsg,
  isNotValidate,
  errorTextClass,
  minDate,
  maxDate
}) => {
    return (
    <div className={`flex flex-col w-full ${mainClass}`}>
      {title && (
        <div className="flex mb-1 text-sm items-center text-center">
          <span className={`text-secondary font-bold ${textColor}`}>
            {title}
          </span>
        </div>
      )}
      {rows ? (
        <textarea
          onBlur={onBlur}
          rows={rows}
          className={`${className} INPUT ${title ? "px-0" : ""}`}
          name={name}
          value={val}
          placeholder={
            placeHolder === ("" || undefined) ? `Enter ${title}` : placeHolder
          }
          onChange={onChange}
          onKeyDown={onKeyDown}
          touched={touched}
        />
      ) : (
        <div className={`${className} INPUT ${title ? "px-0" : ""}`}>
          {lComp}
          <input
            onBlur={onBlur}
            touched={touched}
            readOnly={readOnly}
            maxLength={maxLength}
            min={minDate}
            max={maxDate}
            name={name}
            disabled={isDisabled}
            type={type}
            value={val}
            className={`${InputclassName} w-full bg-transparent outline-none `}
            placeholder={
              placeHolder === ("" || undefined) ? `Enter ${title}` : placeHolder
            }
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          {rComp}
        </div>
      )}
      {!isNotValidate ? (
        errorMsg && touched && !isDisabled ? (
          <span className={` text-red-600 ${errorTextClass}`}>{errorMsg}</span>
        ) : (
          <span className={`mb-[22px]`}></span>
        )
      ) : null}
    </div>
  );
};

export default INPUT;
